
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import MainSection from './components/MainSection';
import Application2 from './components/Application2'; 
import BrochureBack from './components/BrochureBack'; 
import BrochureFront from './components/BrochureFront'; 
import Authorization from './components/Authorization'; 
import HandBook from './components/HandBook'; 
import Employee from './components/Employee'; 
import NursingStaffSection from './components/NursingStaffSection';
import HourlyRate from './components/HourlyRate';
import MedicalInfoSection from './components/MedicalInfoSection';
import Patients from './components/Patients';
import Footer from './components/Footer';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={
                        <>
                            <Navbar />
                            <MainSection />
                            <NursingStaffSection />
                            <HourlyRate />
                            <MedicalInfoSection />
                            <Patients />
                            <Footer />
                        </>
                    } />
                    <Route path="/application2" element={<Application2 />} />
                    <Route path="/authorization" element={<Authorization />} /> 
                    <Route path="/brochureback" element={<BrochureBack />} /> 
                    <Route path="/brochurefront" element={<BrochureFront />} />
                    <Route path="/employee" element={<Employee />} />
                    <Route path="/handbook" element={<HandBook />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
